import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getInventoryRecord, updateInventoryRecord } from '../../../actions/InventoryRecord';
import PageHeader from '../../Common/PageHeader';
import RecordForm from './RecordForm';
import {
  getDataAdministrators,
  getAuthorities,
  getInventoryRecordAttachmentTypes
} from '../../../actions/Options';
import moment from 'moment';

class UpdateRecordContainer extends Component {
  constructor(props) {
    super(props);
    this.returnUrl =
      props.location.state && props.location.state.returnUrl ? props.location.state.returnUrl : '';

    this.state = {
      error: '',
      contractor: '',
      boces_name: 'BOCES',
      criticality: '',
      data_classification_risk: '',
      expires_at: null,
      data_admin_id: '',
      data_admin_title: '',
      authorities: [],
      data_classifications: [],
      number_of_users: '',
      number_of_records: '',
      renews_annually: false,
      attachments: [],
      archived: false,

      system_termination_date: null,
      system_implementation_date: null,
      systems_category: '',
      interoperability_data_in: '',
      interoperability_data_out: '',
      hosting_location: '',
      additional_notes: '',

      building_licenses: {},
      building_notes: {},
      dateFocused: ''
    };
  }

  saveRecord = async () => {
    const { inventoryId } = this.props.match.params;
    const state = this.state;

    try {
      const record = {
        ...this.state,
        boces_name: this.state.contractor !== 'BOCES' ? '' : this.state.boces_name,
        attachments: this.state.attachments.map((attachment) => {
          delete attachment.index;
          return attachment;
        }),
        expires_at: state.expires_at
          ? moment.utc(state.expires_at).toISOString().split('T')[0]
          : null,
        system_implementation_date: state.system_implementation_date
          ? state.system_implementation_date.toISOString().split('T')[0]
          : null,
        system_termination_date: state.system_termination_date
          ? state.system_termination_date.toISOString().split('T')[0]
          : null
      };

      await this.props.updateInventoryRecord(inventoryId, record);

      if (this.returnUrl) {
        this.props.history.push(this.returnUrl);
      } else {
        this.props.history.push(`/inventory/${record.id}`);
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };

  async componentDidMount() {
    const { inventoryId } = this.props.match.params;

    this.props.getInventoryRecord(inventoryId);
    this.props.getAuthorities();

    const currentRecord = this.props.records[inventoryId];
    if (currentRecord) {
      this.setState(this.getStateFromRecord(currentRecord));
      this.props.getDataAdministrators(currentRecord.district_id);
    }
  }

  async componentWillMount() {
    this.props.getInventoryRecordAttachmentTypes();
  }

  addAttachment = (newAttachment) => {
    this.setState({
      attachments: this.state.attachments.concat({
        ...newAttachment,
        name: newAttachment.name || newAttachment.type,
        index: this.state.attachments.length
      })
    });
  };

  updateAttachment = (updatedAttachment) => {
    this.setState({
      attachments: this.state.attachments.map((existingAttachment) => {
        let shouldUpdate = false;

        if (typeof updatedAttachment.id !== 'undefined') {
          if (existingAttachment.id === updatedAttachment.id) {
            shouldUpdate = true;
          }
        } else if (
          typeof updatedAttachment.index !== 'undefined' &&
          existingAttachment.index === updatedAttachment.index
        ) {
          shouldUpdate = true;
        }

        return shouldUpdate
          ? {
              ...updatedAttachment,
              name: updatedAttachment.name || updatedAttachment.type
            }
          : existingAttachment;
      })
    });
  };

  updateError = (err) => {
    this.setState({ error: err });
  };

  removeAttachment = (attachment) => {
    this.setState({
      attachments: this.state.attachments.filter((x) => x !== attachment)
    });
  };

  selectClassification = (classificationId) => {
    this.setState({
      data_classifications: [...this.state.data_classifications, classificationId]
    });
  };

  removeClassification = (classificationId) => {
    const currentClassifications = this.state.data_classifications;
    const newClassifications = currentClassifications.filter((x) => x !== classificationId);
    this.setState({ data_classifications: newClassifications });
  };

  selectAuthority = (authorityId) => {
    this.setState({
      authorities: [...this.state.authorities, authorityId]
    });
  };

  removeAuthority = (authorityId) => {
    const currentAuths = this.state.authorities;
    const newAuthorities = currentAuths.filter((x) => x !== authorityId);

    this.setState({ authorities: newAuthorities });
  };

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDataAdmin = (e) => {
    e.preventDefault();

    const { inventoryId } = this.props.match.params;
    const record = this.props.records[inventoryId];
    const districtId = record.district_id;

    const dataAdmins = this.props.dataAdmins[districtId];
    const newId = e.target.value;

    if (newId) {
      const selectedAdmin = dataAdmins[newId];

      this.setState({
        [e.target.name]: e.target.value,
        data_admin_title: selectedAdmin.title || ''
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        data_admin_title: ''
      });
    }
  };

  changeFocus = (newFocus) => {
    this.setState({ dateFocused: newFocus });
  };

  updateBuildingLicenses = (building, value) => {
    this.setState({
      building_licenses: { ...this.state.building_licenses, [building]: value >= 0 ? value : 0 }
    });
  };

  updateBuildingNotes = (building, value) => {
    this.setState({
      building_notes: { ...this.state.building_notes, [building]: value }
    });
  };

  getStateFromRecord = (record) => {
    return {
      ...record,
      expires_at: record.expires_at ? moment.utc(record.expires_at) : null,
      contractor: record.contractor || '',
      boces_name: record.boces_name || '',
      data_admin_id: record.data_admin_id || '',
      data_admin_title: record.data_admin ? record.data_admin.title : '',
      number_of_users: record.number_of_users || '',
      number_of_records: record.number_of_records || '',
      data_classifications: record.data_classifications.map((x) => x.id),
      authorities: record.authorities.map((x) => `${x.id}`),
      renews_annually: record.renews_annually,
      archived: record.archived,
      attachments: record.attachments || [],

      building_licenses: record.building_licenses || {},
      building_notes: record.building_notes || {},
      systems_category: record.systems_category || '',
      interoperability_data_in: record.interoperability_data_in || '',
      interoperability_data_out: record.interoperability_data_out || '',
      hosting: record.hosting || '',
      additional_notes: record.additional_notes || '',
      system_implementation_date: record.system_implementation_date
        ? moment(record.system_implementation_date)
        : null,
      system_termination_date: record.system_termination_date
        ? moment(record.system_termination_date)
        : null
    };
  };

  async componentWillReceiveProps(nextProps) {
    const oldMatch = this.props.match;
    const { match } = nextProps;
    const oldId = oldMatch.params.inventoryId;
    const newId = match.params.inventoryId;
    const recordIdChanged = oldId !== newId;

    if (recordIdChanged) {
      this.props.getInventoryRecord(newId);
      return;
    }

    const oldRecord = this.props.records[oldId] || {};
    const newRecord = nextProps.records[newId] || {};
    if (oldRecord.id !== newRecord.id) {
      this.setState(this.getStateFromRecord(newRecord));

      this.props.getDataAdministrators(newRecord.district_id);
    }
  }

  addNumberOfUsersOption = (option) => {
    this.setState({
      number_of_users: this.state.number_of_users
        .split(',')
        .filter((x) => x)
        .concat(option)
        .join(',')
    });
  };

  removeNumberOfUsersOption = (option) => {
    this.setState({
      number_of_users: this.state.number_of_users
        .split(',')
        .filter((x) => x && x !== option)
        .join(',')
    });
  };

  render() {
    const { inventoryId } = this.props.match.params;
    const record = this.props.records[inventoryId];

    if (!record) {
      return <div>Loading...</div>;
    }

    const dataAdmins = this.props.dataAdmins[record.district_id];
    const possibleAuthorities = this.props.authorities;

    if (!dataAdmins || !possibleAuthorities) {
      return <div>Loading...</div>;
    }

    return (
      <div className="update-record-container">
        <PageHeader title="Products" subtitle="Update Inventory Record" />
        <p className="lead">
          Update District Inventory Record for the product <b>{record.product.name}</b> in the
          District Inventory for <b>{record.district.name}</b>
        </p>
        <RecordForm
          {...this.state}
          all_data_classifications={record.all_data_classifications}
          attachmentTypes={this.props.attachmentTypes}
          original_data_admin_id={record.data_admin_id}
          inventoryId={inventoryId}
          handleChange={this.handleChange}
          product={record.product}
          district={record.district}
          dataAdmins={dataAdmins}
          selectClassification={this.selectClassification}
          removeClassification={this.removeClassification}
          possibleAuthorities={possibleAuthorities}
          selectAuthority={this.selectAuthority}
          removeAuthority={this.removeAuthority}
          changeDataAdmin={this.changeDataAdmin}
          changeFocus={this.changeFocus}
          addNumberOfUsersOption={this.addNumberOfUsersOption}
          removeNumberOfUsersOption={this.removeNumberOfUsersOption}
          handleCheckbox={this.handleCheckbox}
          saveRecord={this.saveRecord}
          currentUser={this.props.currentUser}
          addAttachment={this.addAttachment}
          removeAttachment={this.removeAttachment}
          updateAttachment={this.updateAttachment}
          updateError={this.updateError}
          updateBuildingLicenses={this.updateBuildingLicenses}
          updateBuildingNotes={this.updateBuildingNotes}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.Account.currentUser,
    dataAdmins: state.Options.dataAdmins,
    records: state.InventoryRecord.inventoryRecordDict,
    authorities: state.Options.authorities,
    attachmentTypes: state.Options.inventoryRecordAttachmentTypes
  };
};

const actions = {
  getDataAdministrators,
  getAuthorities,
  getInventoryRecord,
  updateInventoryRecord,
  getInventoryRecordAttachmentTypes
};

UpdateRecordContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UpdateRecordContainer)
);

export default UpdateRecordContainer;
