import React from 'react';
import { NavLink } from 'react-router-dom';
import renderFor from '../../utils/renderFor';
import DPSSInventoryToolReleaseNotesv411 from '../../static/DPSSInventoryToolReleaseNotesv4.3.1.pdf';
import { Navbar, Nav as BsNav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Nav.css';
import { encode } from '../../utils/cryptoHelper';
import {
  getDistrictInventoryPath,
  getUserHomePath,
  getServiceProvidersPath
} from '../../utils/paths';
import config from '../../config';
import { getAuthKey } from '../../utils/cookieHelper';

const Nav = ({
  currentUser,
  exportDistrictData,
  exportDistrictDataAdvanced,
  exportRegionData,
  exportRegionDataAdvanced
}) => {
  let navIndex = 1;

  const dpitVersionNo = 'v4.3.1';

  return (
    <Navbar fixedTop className="navbar-ricone" fluid={true}>
      <Navbar.Header>
        <Navbar.Brand>
          <NavLink
            style={{
              pointerEvents: currentUser === null ? 'none' : 'auto'
            }}
            to={getUserHomePath(currentUser)}
            exact={true}
            strict={true}
            activeClassName="">
            {currentUser === null
              ? `District Data Privacy Inventory ${dpitVersionNo}`
              : `DPIT ${dpitVersionNo}`}
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      {currentUser ? (
        <Navbar.Collapse>
          <BsNav>
            {renderFor(
              {
                companyAdmin: true,
                regionAdmin: true,
                districtAdmin: true,
                districtSystemAdmin: true,
                superAdmin: true
              },
              () => {
                return (
                  <NavDropdown eventKey={navIndex++} title="Products" id="product-dropdown">
                    <LinkContainer exact to="/products">
                      <MenuItem eventKey={1.1}>All Products</MenuItem>
                    </LinkContainer>
                    {renderFor(
                      {
                        companyAdmin: true,
                        superAdmin: true
                      },
                      () => {
                        return (
                          <LinkContainer exact to="/products/create">
                            <MenuItem eventKey={1.2}>Add Product</MenuItem>
                          </LinkContainer>
                        );
                      }
                    )}
                    {/*<LinkContainer exact to="/products/register/all">
                        <MenuItem eventKey={1.3}>Registered Product</MenuItem>
                      </LinkContainer>*/}
                  </NavDropdown>
                );
              }
            )}

            {renderFor(
              {
                districtAdmin: true,
                districtSystemAdmin: true,
                superAdmin: true
              },
              () => {
                return (
                  <LinkContainer to="/companies">
                    <NavItem eventKey={navIndex++}>Companies</NavItem>
                  </LinkContainer>
                );
              }
            )}

            {renderFor({ companyAdmin: true, superAdmin: false }, () => {
              return (
                <LinkContainer to={`/companies/${currentUser.entity_id}`}>
                  <NavItem eventKey={navIndex++}>My Company</NavItem>
                </LinkContainer>
              );
            })}

            {renderFor({}, () => {
              return (
                <NavDropdown eventKey={navIndex++} title="Categories" id="class-dropdown">
                  <LinkContainer exact to="/categories">
                    <MenuItem eventKey={3.1}>See Categories</MenuItem>
                  </LinkContainer>
                  <LinkContainer exact to="/categories/create">
                    <MenuItem eventKey={3.2}>Add Category</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}

            {renderFor(
              {
                regionAdmin: true
              },
              () => {
                return (
                  <NavDropdown eventKey={navIndex++} title="Districts" id="districts-dropdown">
                    <LinkContainer to="/districts">
                      <MenuItem eventKey={4.1}>See Districts</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/districts/create">
                      <MenuItem eventKey={4.2}>Add District</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/districts/document/selector">
                      <MenuItem eventKey={4.3}>Document Library</MenuItem>
                    </LinkContainer>
                  </NavDropdown>
                );
              }
            )}

            {renderFor(
              { districtAdmin: true, districtSystemAdmin: true, superAdmin: false },
              () => {
                return (
                  <LinkContainer to={`/districts/${currentUser.entity_id}/documents`}>
                    <NavItem eventKey={navIndex++}>Document Library</NavItem>
                  </LinkContainer>
                );
              }
            )}

            {renderFor({}, () => {
              return (
                <NavDropdown eventKey={navIndex++} title="Regions" id="regions-dropdown">
                  <LinkContainer to="/regions">
                    <MenuItem eventKey={5.1}>See Regions</MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/regions/create">
                    <MenuItem eventKey={5.2}>Add Region</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}

            {renderFor({}, () => {
              return (
                <NavDropdown eventKey={navIndex++} title="Users" id="users-dropdown">
                  <LinkContainer exact to="/users">
                    <MenuItem eventKey={6.1}>See Users</MenuItem>
                  </LinkContainer>
                  <LinkContainer exact to="/users/create">
                    <MenuItem eventKey={6.2}>Add User</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}

            {renderFor({ superAdmin: false, districtAdmin: true }, () => {
              return (
                <NavDropdown eventKey={navIndex++} title="Data Administrators" id="data-dropdown">
                  <LinkContainer exact to="/users">
                    <MenuItem eventKey={7.1}>See Data Administrators</MenuItem>
                  </LinkContainer>
                  <LinkContainer exact to="/users/create">
                    <MenuItem eventKey={7.2}>Add Data Administrator</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}

            {renderFor(
              { superAdmin: false, districtAdmin: true, districtSystemAdmin: true },
              () => {
                return (
                  <NavDropdown eventKey={navIndex++} title="Export Data" id="data-dropdown">
                    <MenuItem eventKey={7.1} onSelect={exportDistrictData}>
                      Basic Export
                    </MenuItem>
                    <MenuItem eventKey={7.2} onSelect={exportDistrictDataAdvanced}>
                      Advanced Export
                    </MenuItem>
                  </NavDropdown>
                );
              }
            )}

            {renderFor(
              {
                superAdmin: false,
                districtAdmin: false,
                districtSystemAdmin: false,
                regionAdmin: true
              },
              () => {
                return (
                  <NavDropdown eventKey={navIndex++} title="Export Data" id="data-dropdown">
                    <MenuItem eventKey={7.1} onSelect={exportRegionData}>
                      Basic Export
                    </MenuItem>
                    <MenuItem eventKey={7.2} onSelect={exportRegionDataAdvanced}>
                      Advanced Export
                    </MenuItem>
                  </NavDropdown>
                );
              }
            )}

            {renderFor({ regionAdmin: false, superAdmin: false, districtSystemAdmin: true }, () => {
              return (
                <NavDropdown
                  eventKey={navIndex++}
                  title="District and Data Administrators"
                  id="data-dropdown">
                  <LinkContainer to="/users" exact>
                    <MenuItem eventKey={7.1}>See District and Data Administrators</MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/users/create" exact>
                    <MenuItem eventKey={7.2}>Add District or Data Administrator</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}
            {renderFor({ regionAdmin: true, superAdmin: false }, () => {
              return (
                <NavDropdown
                  eventKey={navIndex++}
                  title="District and Data Administrators"
                  id="data-dropdown">
                  <LinkContainer to="/users/selector">
                    <MenuItem eventKey={7.1}>See District and Data Administrators</MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/users/create">
                    <MenuItem eventKey={7.2}>Add District or Data Administrator</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              );
            })}

            {renderFor(
              {
                regionAdmin: true,
                districtAdmin: true,
                districtSystemAdmin: true,
                dataAdmin: true
              },
              () => {
                return (
                  <NavDropdown
                    eventKey={navIndex++}
                    title="District Inventory"
                    id="inventory-dropdown">
                    <LinkContainer to={getDistrictInventoryPath(currentUser)}>
                      <MenuItem eventKey={8.1}>District Products</MenuItem>
                    </LinkContainer>

                    <LinkContainer to={getServiceProvidersPath(currentUser)}>
                      <MenuItem eventKey={8.2}>Service Providers</MenuItem>
                    </LinkContainer>

                    {renderFor(
                      {
                        districtAdmin: true,
                        districtSystemAdmin: true,
                        dataAdmin: true,
                        superAdmin: false
                      },
                      () => {
                        const url = `/supplemental-information/${encode(currentUser.entity_id)}`;
                        return (
                          <LinkContainer to={url}>
                            <MenuItem eventKey={8.3}>Supplemental Information</MenuItem>
                          </LinkContainer>
                        );
                      }
                    )}
                  </NavDropdown>
                );
              }
            )}

            {renderFor(
              {
                regionAdmin: true,
                superAdmin: true
              },
              () => {
                return (
                  <LinkContainer to="/reports">
                    <NavItem eventKey={navIndex++}>Reports</NavItem>
                  </LinkContainer>
                );
              }
            )}

            {renderFor(
              {
                superAdmin: true
              },
              () => {
                return (
                  <NavItem
                    href={`${config.apiUrl}reports/region-admin-export?api_token=${getAuthKey()}`}>
                    Export Regional User Report
                  </NavItem>
                );
              }
            )}

            {renderFor({}, () => {
              return (
                <LinkContainer to={'/admin/security-settings'}>
                  <NavItem eventKey={navIndex++}>Security Settings</NavItem>
                </LinkContainer>
              );
            })}

            <NavItem href={DPSSInventoryToolReleaseNotesv411}>Release Notes</NavItem>
          </BsNav>
          <BsNav pullRight>
            {renderFor(
              { districtAdmin: true, districtSystemAdmin: true, superAdmin: false },
              () => {
                return (
                  <LinkContainer to={`/districts/${currentUser.entity_id}/update`}>
                    <NavItem eventKey={navIndex++}>District Settings</NavItem>
                  </LinkContainer>
                );
              }
            )}
            <NavDropdown
              eventKey={navIndex++}
              title={`${currentUser.first_name} ${currentUser.last_name}`}
              id="account-dropdown">
              <LinkContainer to="/my-account">
                <MenuItem eventKey={7.1}>My Account</MenuItem>
              </LinkContainer>
              <MenuItem divider />
              <LinkContainer to="/logout">
                <MenuItem eventKey={7.2}>
                  {currentUser.temporary ? 'Restore Region Admin Session' : 'Logout'}
                </MenuItem>
              </LinkContainer>
            </NavDropdown>
          </BsNav>
        </Navbar.Collapse>
      ) : null}
    </Navbar>
  );
};

export default Nav;
