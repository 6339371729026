import React from 'react';
import { SingleDatePicker } from 'react-dates';
import ErrorAlert from '../../Common/ErrorAlert';
import GoBack from '../../Common/GoBack';
import AttachmentContainer from '../../Common/Attachments/AttachmentContainer';
import NewAttachmentContainer from '../../Common/Attachments/NewAttachmentContainer';

const RecordForm = ({
  addNumberOfUsersOption,
  addAttachment,
  all_data_classifications,
  attachments,
  attachmentTypes,
  authorities,
  contractor,
  boces_name,
  changeDataAdmin,
  changeFocus,

  dataAdmins,
  data_admin_id,
  data_admin_title,
  data_classifications,
  error,
  expires_at,
  archived,

  handleCheckbox,
  inventoryId,
  handleChange,
  number_of_users,
  possibleAuthorities,
  product,
  removeAuthority,
  renews_annually,
  removeClassification,
  removeNumberOfUsersOption,
  saveRecord,
  selectAuthority,
  selectClassification,
  updateAttachment,
  updateError,
  removeAttachment,

  building_licenses,
  updateBuildingLicenses,

  building_notes,
  updateBuildingNotes,

  dateFocused,

  system_implementation_date,
  system_termination_date,
  interoperability_data_in,
  interoperability_data_out,
  additional_notes,
  hosting_location,
  systems_category,
  criticality,
  data_classification_risk
}) => {
  const existingAttachments = attachments.map((x, index) => {
    return (
      <AttachmentContainer
        key={index}
        attachment={x}
        index={index}
        attachmentTypes={attachmentTypes}
        updateAttachment={updateAttachment}
        removeAttachment={removeAttachment}
        updateError={updateError}
        showInBORCheckbox
      />
    );
  });

  const dataAdminOptions = Object.keys(dataAdmins).map((id) => {
    return (
      <option key={id} value={id}>
        {dataAdmins[id].first_name} {dataAdmins[id].last_name}
      </option>
    );
  });
  const classificationList = all_data_classifications.map((classification) => {
    return (
      <div className="checkbox" key={classification.id}>
        <label>
          <input
            type="checkbox"
            name="data_classifications"
            checked={data_classifications.indexOf(classification.id) >= 0}
            onChange={(e) => {
              if (e.target.checked) {
                selectClassification(classification.id);
              } else {
                removeClassification(classification.id);
              }
            }}
          />
          {classification.name}
        </label>
      </div>
    );
  });

  const authorityOptions = Object.keys(possibleAuthorities).map((id) => {
    return (
      <div className="checkbox" key={id}>
        <label>
          <input
            type="checkbox"
            name="authorities"
            checked={authorities.indexOf(id) >= 0}
            onChange={(e) => {
              if (e.target.checked) {
                selectAuthority(id);
              } else {
                removeAuthority(id);
              }
            }}
          />
          {possibleAuthorities[id]}
        </label>
      </div>
    );
  });

  return (
    <div className="row">
      <div className="col-sm-12">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveRecord();
          }}>
          <hr />

          <div className="row">
            <div className="col-xs-8">
              <p className="lead d-inline-block">
                <b>Required for Supplemental Information</b>
              </p>
            </div>
            <div className="col-xs-4 text-right">
              <button type="submit" className="btn btn-primary">
                {inventoryId ? 'Update Inventory Record' : 'Add To District Inventory'}
              </button>
              <GoBack />
            </div>
            <div className="col-xs-12">
              <p className="lead">Contract Information</p>
            </div>
            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="contractor">Contract Source</label>
              <select
                name="contractor"
                className="form-control"
                onChange={handleChange}
                value={contractor || 'undefined'}>
                <option value="">Select a Contract Source</option>
                <option value="District">District</option>
                <option value="BOCES">BOCES</option>
                <option value="District - Free">District - Free</option>
              </select>

              <br></br>

              {contractor === 'BOCES' ? (
                <>
                  <label htmlFor="boces_name">BOCES name: (Optional)</label>
                  <input
                    onChange={handleChange}
                    id="boces_name"
                    type="text"
                    name="boces_name"
                    placeholder="Enter a specific BOCES name"
                    className="form-control"
                    value={boces_name}
                  />
                </>
              ) : (
                ''
              )}
            </div>
            <div className="form-group col-xs-6 col-sm-5">
              <label htmlFor="expires_at">Contract Expiration Date (Format: MM/DD/YYYY )</label>
              <div>
                <SingleDatePicker
                  date={expires_at}
                  showClearDate={true}
                  readOnly={true}
                  isOutsideRange={() => false}
                  onDateChange={(value) =>
                    handleChange({
                      target: { name: 'expires_at', value },
                      preventDefault: () => {}
                    })
                  }
                  focused={dateFocused === 'expires_at'}
                  onFocusChange={({ focused }) => changeFocus(focused ? 'expires_at' : '')}
                  numberOfMonths={1}
                  id="expires_at"
                  placeholder="Select a date"
                  withPortal={true}
                />
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="renews_annually"
                    checked={renews_annually}
                    onChange={handleCheckbox}
                  />
                  Renews Annually
                </label>
              </div>
            </div>
            <div className="col-xs-12 col-sm-10">
              <p className="lead">Supporting Documents (max 6)</p>

              {existingAttachments}

              {existingAttachments.length < 6 ? (
                <NewAttachmentContainer
                  attachmentTypes={attachmentTypes}
                  saveAttachment={addAttachment}
                  updateError={updateError}
                  showInBORCheckbox
                  updateValue={handleChange}
                />
              ) : null}
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-xs-12">
              <p className="lead">
                <b>Additional Information</b>
              </p>
            </div>
            <div className="col-xs-12">
              <p className="lead">Licensing Information</p>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <label htmlFor="number_of_users">Buildings</label>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <label htmlFor="number_of_users">Licenses</label>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <label htmlFor="notes">Notes</label>
            </div>
          </div>

          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="districtWide"
                    checked={number_of_users.indexOf('District Wide') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('District Wide');
                      } else {
                        removeNumberOfUsersOption('District Wide');
                      }
                    }}
                  />
                  District Wide
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <input
                id="district_wide_licenses"
                type="number"
                min={0}
                name="district_wide_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.district_wide || ''}
                onChange={(e) => updateBuildingLicenses('district_wide', e.target.value)}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="district_wide_notes"
                name="district_wide_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.district_wide || ''}
                onChange={(e) => {
                  updateBuildingNotes('district_wide', e.target.value);
                }}
              />
            </div>
          </div>

          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="highSchool"
                    checked={number_of_users.indexOf('High School') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('High School');
                      } else {
                        removeNumberOfUsersOption('High School');
                      }
                    }}
                  />
                  High School
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <input
                id="high_school_licenses"
                type="number"
                min={0}
                name="high_school_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.high_school || ''}
                onChange={(e) => {
                  updateBuildingLicenses('high_school', e.target.value);
                }}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="high_school_notes"
                name="high_school_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.high_school || ''}
                onChange={(e) => {
                  updateBuildingNotes('high_school', e.target.value);
                }}
              />
            </div>
          </div>

          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="middleSchool"
                    checked={number_of_users.indexOf('Middle School') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('Middle School');
                      } else {
                        removeNumberOfUsersOption('Middle School');
                      }
                    }}
                  />
                  Middle School
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              {/*<label htmlFor="high_school_licenses">Middle School Licenses</label>*/}
              <input
                id="middle_school_licenses"
                type="number"
                min={0}
                name="middle_school_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.middle_school || ''}
                onChange={(e) => updateBuildingLicenses('middle_school', e.target.value)}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="middle_school_notes"
                name="middle_school_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.middle_school || ''}
                onChange={(e) => {
                  updateBuildingNotes('middle_school', e.target.value);
                }}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="intermediateSchool"
                    checked={number_of_users.indexOf('Intermediate School') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('Intermediate School');
                      } else {
                        removeNumberOfUsersOption('Intermediate School');
                      }
                    }}
                  />
                  Intermediate School
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              {/*<label htmlFor="high_school_licenses">Intermediate School Licenses</label>*/}
              <input
                id="intermediate_school_licenses"
                type="number"
                min={0}
                name="intermediate_school_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.intermediate_school || ''}
                onChange={(e) => updateBuildingLicenses('intermediate_school', e.target.value)}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="intermediate_school_notes"
                name="intermediate_school_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.intermediate_school || ''}
                onChange={(e) => {
                  updateBuildingNotes('intermediate_school', e.target.value);
                }}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="elementarySchool"
                    checked={number_of_users.indexOf('Elementary School') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('Elementary School');
                      } else {
                        removeNumberOfUsersOption('Elementary School');
                      }
                    }}
                  />
                  Elementary School
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              {/*<label htmlFor="elementary_school_licenses">Elementary School Licenses</label>*/}
              <input
                id="elementary_school_licenses"
                type="number"
                min={0}
                name="elementary_school_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.elementary_school || ''}
                onChange={(e) => updateBuildingLicenses('elementary_school', e.target.value)}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="elementary_school_notes"
                name="elementary_school_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.elementary_school || ''}
                onChange={(e) => {
                  updateBuildingNotes('elementary_school', e.target.value);
                }}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className="form-group col-xs-4 col-sm-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="districtoffice"
                    checked={number_of_users.indexOf('District Office') >= 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addNumberOfUsersOption('District Office');
                      } else {
                        removeNumberOfUsersOption('District Office');
                      }
                    }}
                  />
                  District Office
                </label>
              </div>
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              {/*<label htmlFor="district_office_licenses">District Office Licenses</label>*/}
              <input
                id="district_office_licenses"
                type="number"
                min={0}
                name="district_office_licenses"
                placeholder="0"
                className="form-control"
                value={building_licenses.district_office || ''}
                onChange={(e) => updateBuildingLicenses('district_office', e.target.value)}
              />
            </div>
            <div className="form-group col-xs-4 col-sm-2">
              <textarea
                id="district_office_notes"
                name="district_office_notes"
                placeholder="Notes"
                rows="1"
                className="form-control"
                maxLength={512}
                value={building_notes.district_office || ''}
                onChange={(e) => {
                  updateBuildingNotes('district_office', e.target.value);
                }}
              />
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-xs-12">
              <p className="lead">Product Information</p>
            </div>
            <div className="form-group col-xs-12 col-sm-12">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="archived"
                    checked={archived}
                    onChange={handleCheckbox}
                  />
                  Archived
                </label>
              </div>
            </div>

            {/* System Implementation Date */}
            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="system_implementation_date">Product Implementation Date</label>
              <div>
                <SingleDatePicker
                  id="system_implementation_date"
                  isOutsideRange={() => false}
                  date={system_implementation_date}
                  showClearDate={true}
                  readOnly={false}
                  onDateChange={(value) =>
                    handleChange({
                      target: { name: 'system_implementation_date', value },
                      preventDefault: () => {}
                    })
                  }
                  focused={dateFocused === 'system_implementation_date'}
                  onFocusChange={({ focused }) =>
                    changeFocus(focused ? 'system_implementation_date' : '')
                  }
                  numberOfMonths={1}
                  keepOpenOnDateSelect={false}
                  withPortal={true}
                />
              </div>
            </div>

            {/* System Termination Date */}
            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="system_termination_date">Product Termination Date</label>
              <div>
                <SingleDatePicker
                  id="system_termination_date"
                  isOutsideRange={() => false}
                  date={system_termination_date}
                  showClearDate={true}
                  readOnly={false}
                  onDateChange={(value) =>
                    handleChange({
                      target: { name: 'system_termination_date', value },
                      preventDefault: () => {}
                    })
                  }
                  focused={dateFocused === 'system_termination_date'}
                  onFocusChange={({ focused }) =>
                    changeFocus(focused ? 'system_termination_date' : '')
                  }
                  numberOfMonths={1}
                />
              </div>
            </div>

            <div className="clearfix" />

            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="hosting_location">Hosting Location</label>
              <select
                name="hosting_location"
                className="form-control"
                onChange={handleChange}
                value={hosting_location || 'undefined'}>
                <option value="">Select hosting</option>
                <option value="Offsite">Offsite</option>
                <option value="Onsite">Onsite</option>
                <option value="RIC">RIC</option>
              </select>
            </div>
            {/* Systems Category */}
            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="systems_category">Product Category</label>
              <select
                name="systems_category"
                className="form-control"
                onChange={handleChange}
                value={systems_category || 'undefined'}>
                <option value="">Select category</option>
                <option value="Communications">Communications</option>
                <option value="Operations">Operations</option>
                <option value="Student Safety, Academics, and Services">
                  Student Safety, Academics, and Services
                </option>
              </select>
            </div>

            <div className="clearfix" />

            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="systems_category">Data Classification Risk</label>
              <select
                name="data_classification_risk"
                className="form-control"
                onChange={handleChange}
                value={data_classification_risk || 'undefined'}>
                <option value="">Select Risk</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>

            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="systems_category">Criticality</label>
              <select
                name="criticality"
                className="form-control"
                onChange={handleChange}
                value={criticality || 'undefined'}>
                <option value="">Select criticality</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>

            <div className="clearfix" />

            <div className="form-group col-xs-6 col-sm-3">
              <label htmlFor="data_classifications">Data Categories</label>
              {classificationList}
            </div>

            <div className="clearfix" />

            {/* Interoperability: Data In */}
            <div className="form-group col-xs-12 col-sm-6">
              <label htmlFor="interoperability_data_in">Interoperability: Data In</label>
              <textarea
                onChange={handleChange}
                id="interoperability_data_in"
                name="interoperability_data_in"
                placeholder="Interoperability: Data In"
                className="form-control"
                style={{ height: '75px' }}
                maxLength={512}
                value={interoperability_data_in}
              />
            </div>

            <div className="clearfix" />

            {/* Interoperability: Data Out */}
            <div className="form-group col-xs-12 col-sm-6">
              <label htmlFor="interoperability_data_out">Interoperability: Data Out</label>
              <textarea
                onChange={handleChange}
                id="interoperability_data_out"
                name="interoperability_data_out"
                placeholder="Interoperability: Data Out"
                className="form-control"
                style={{ height: '75px' }}
                maxLength={512}
                value={interoperability_data_out}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-xs-12">
              <p className="lead">Additional Notes</p>
            </div>
            <div className="form-group col-xs-6 col-sm-6">
              <label htmlFor="additional_notes">Additional Notes</label>
              <textarea
                rows={4}
                name="additional_notes"
                id={'additional_notes'}
                className="form-control"
                onChange={handleChange}
                value={additional_notes || ''}
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-xs-12">
              <p className="lead">Data Administrator</p>
            </div>
            <div className="form-group col-xs-6 col-sm-5">
              <label htmlFor="data_admin_id">Data Administrator</label>
              <select
                id="data_admin_id"
                name="data_admin_id"
                className="form-control"
                value={data_admin_id || 'undefined'}
                onChange={changeDataAdmin}>
                <option value="">No Data Administrator</option>
                {dataAdminOptions}
              </select>
            </div>

            <div className="form-group col-xs-6 col-sm-5">
              <label htmlFor="authorities">Authorities</label>
              {authorityOptions}
            </div>

            <div className="form-group col-xs-6 col-sm-5">
              <label htmlFor="data_admin_title">Title</label>
              <input
                readOnly={true}
                id="data_admin_title"
                type="text"
                name="data_admin_title"
                placeholder="data administrator title"
                className="form-control"
                value={data_admin_title}
              />
            </div>
          </div>

          <hr />

          <div className="col-12 col-sm-10 text-right">
            <ErrorAlert error={error} />
            <button type="submit" className="btn btn-primary">
              {inventoryId ? 'Update Inventory Record' : 'Add To District Inventory'}
            </button>
            <GoBack />
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecordForm;
